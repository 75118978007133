<template>
  <div class="single-page" v-loading="loadingDetail">
    <div class="single-page-content" v-html="contentBody"></div>
  </div>
</template>

<script>
import { getModuleTypeMes } from "@/api/base.js";

export default {
  name: "SinglePage",
  data() {
    return {
      loadingDetail: false,
      searchObj: {
        moduleCode: "",
        typeId: 0,
      },
      contentBody: {},
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        const { query } = val;
        this.searchObj.moduleCode = query.module_code;
        this.searchObj.typeId = query.id;
      },
    },
    "searchObj.typeId"() {
      this.getModuleTypeMes();
    },
  },
  created() {
    // console.log("created", this.searchObj);
    this.getModuleTypeMes();
  },
  methods: {
    getModuleTypeMes() {
      this.loadingDetail = true;
      let params = {
        module_code: this.searchObj.moduleCode,
        type_id: this.searchObj.typeId,
      };
      getModuleTypeMes(params)
        .then((res) => {
          if (res.code === 200) {
            this.contentBody = res.data.type_body;
          }
        })
        .finally(() => {
          this.loadingDetail = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .single-page {
  }
}
@media only screen and (max-width: 768px) {
  .single-page {
  }
}
.single-page {
  overflow-y: auto;

  .single-page-content {
    /deep/ img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>